var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.preloader)?_c('app-overlay-loader'):_c('form',{ref:"form",attrs:{"data-url":`${_vm.apiUrl.PAYROLL_SETTINGS}/payslip`},on:{"submit":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_c('app-form-group',{attrs:{"type":"radio","page":"page","label":_vm.$t('payslip_logo'),"radio-checkbox-name":"payslip_logo","list":[
            {id: 'default', value:this.$t('use_default_logo')},
            {id: 'customize', value:this.$t('customize')},
         ]},model:{value:(_vm.payslipLogo),callback:function ($$v) {_vm.payslipLogo=$$v},expression:"payslipLogo"}}),_vm._v(" "),(_vm.payslipLogo === 'customize')?_c('div',{staticClass:"mt-primary"},[_c('app-form-group',{attrs:{"label":_vm.$fieldLabel('payslip', 'logo'),"page":"page","type":"custom-file-upload","error-message":_vm.$errorMessage(_vm.errors, 'logo'),"generate-file-url":false,"recommendation":this.$t('recommended_company_logo_size'),"file-label":_vm.$fieldLabel('payslip', 'logo')},model:{value:(_vm.image.logo),callback:function ($$v) {_vm.$set(_vm.image, "logo", $$v)},expression:"image.logo"}})],1):_vm._e(),_vm._v(" "),_c('app-form-group',{attrs:{"type":"radio","page":"page","label":_vm.$t('payslip_title'),"radio-checkbox-name":"payslip_title","list":[
            {id: 'default', value:this.$t('use_default_title')},
            {id: 'customize', value:this.$t('customize')},
         ]},model:{value:(_vm.payslipTitle),callback:function ($$v) {_vm.payslipTitle=$$v},expression:"payslipTitle"}}),_vm._v(" "),(_vm.payslipTitle === 'customize')?_c('div',{staticClass:"mt-primary"},[_c('app-form-group',{attrs:{"page":"page","label":'',"type":"text","placeholder":_vm.$placeholder('title', ''),"error-message":_vm.$errorMessage(_vm.errors, 'title')},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1):_vm._e(),_vm._v(" "),_c('app-form-group',{attrs:{"type":"radio","page":"page","label":_vm.$t('payslip_address'),"radio-checkbox-name":"payslip_address","list":[
            {id: 'default', value:this.$t('use_default_address')},
            {id: 'customize', value:this.$t('customize')},
         ]},model:{value:(_vm.payslipAddress),callback:function ($$v) {_vm.payslipAddress=$$v},expression:"payslipAddress"}}),_vm._v(" "),(_vm.payslipAddress === 'customize')?_c('div',{staticClass:"mt-primary"},[_c('app-form-group',{attrs:{"page":"page","type":"textarea","label":'',"placeholder":_vm.$placeholder('address'),"error-message":_vm.$errorMessage(_vm.errors, 'address')},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-3"}),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('app-submit-button',{attrs:{"label":_vm.$t('save'),"loading":_vm.loading},on:{"click":_vm.submitData}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }