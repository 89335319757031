<template>
    
</template>

<script>
    export default {
        name: "ActivityDetails",
        props: {
            value: {},
            rowData: {},
            index: {}
        },
    }
</script>

<style scoped>

</style>
