var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"id":"user-invite","title":_vm.$fieldTitle('invite', 'user', true),"btn-label":_vm.$t('invite'),"scrollable":false,"loading":_vm.loading,"preloader":_vm.preloader},on:{"submit":_vm.submitData},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.isMailSettingExist)?_c('form',{ref:"form",attrs:{"data-url":_vm.submitURL}},[_c('app-form-group',{attrs:{"type":"email","label":_vm.$t('email'),"placeholder":_vm.$placeholder('email'),"required":true,"error-message":_vm.$errorMessage(_vm.errors, 'email')},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_vm._v(" "),_c('app-form-group',{attrs:{"label":_vm.$t('roles'),"type":"multi-select","list":_vm.roles,"error-message":_vm.$errorMessage(_vm.errors, 'roles'),"list-value-field":"name","isAnimatedDropdown":true},model:{value:(_vm.formData.roles),callback:function ($$v) {_vm.$set(_vm.formData, "roles", $$v)},expression:"formData.roles"}})],1):_c('app-note',{attrs:{"title":_vm.$t('no_delivery_settings_found'),"notes":`<ol>
                 <li>${_vm.$t('cron_job_settings_warning',{
                  documentation: `<a href='https://payday.gainhq.com/documentation/important-settings.html#scheduler-queue' target='_blank'>${_vm.$t('documentation')}</a>`
              })}</li>
                 <li>${_vm.$t('no_delivery_settings_warning', {
                  location: `<a href='${_vm.urlGenerator(_vm.TENANT_EMAIL_SETUP_SETTING)}'>${_vm.$t('here')}</a>`
              })}</li>
              </ol>`,"content-type":"html"}}),_vm._v(" "),(!_vm.isMailSettingExist)?_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("\n            "+_vm._s(_vm.$t('close'))+"\n        ")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }