var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"id":"employee-payrun-period-modal","size":"large","title":_vm.userPayrun ? this.$t('edit_payrun') : this.$t('add_payrun'),"preloader":_vm.preloader},on:{"submit":_vm.submit},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('app-form-group',{attrs:{"type":"select","page":"page","label":_vm.$t('payrun_period'),"list":[
            {id:'', value:this.$fieldLabel('choose', 'payrun_period'), disabled: true},
            {id:'monthly', value:this.$t('monthly')},
            {id:'weekly', value:this.$t('weekly')}
         ]},model:{value:(_vm.formData.payrun_period),callback:function ($$v) {_vm.$set(_vm.formData, "payrun_period", $$v)},expression:"formData.payrun_period"}},[_c('template',{slot:"suggestion"},[(_vm.formData.payrun_period === 'monthly')?_c('small',{staticClass:"text-muted font-italic"},[_vm._v("\n                "+_vm._s(_vm.$t('always_run_for_the_last_month'))+"\n            ")]):_vm._e()])],2),_vm._v(" "),_c('app-form-group',{staticClass:"mt-primary",attrs:{"type":"radio","page":"page","label":_vm.$t('payrun_generating_type'),"radio-checkbox-name":"consider_type","list":[
            {id:'hour', value:this.$t('hour')},
            {id:'daily_log', value:this.$t('daily_log')},
            {id:'none', value:this.$t('none')}
         ]},model:{value:(_vm.formData.consider_type),callback:function ($$v) {_vm.$set(_vm.formData, "consider_type", $$v)},expression:"formData.consider_type"}}),_vm._v(" "),(_vm.formData.consider_type === 'hour' || _vm.formData.consider_type === 'daily_log')?_c('div',{staticClass:"mt-primary"},[_c('app-form-group',{attrs:{"label-alignment":"","type":"switch","page":"page","label":this.$t('consider_overtime')},model:{value:(_vm.formData.consider_overtime),callback:function ($$v) {_vm.$set(_vm.formData, "consider_overtime", $$v)},expression:"formData.consider_overtime"}},[(_vm.formData.consider_overtime)?_c('template',{slot:"suggestion"},[_c('small',{staticClass:"mt-2 font-italic"},[_vm._v("\n                    ("+_vm._s(_vm.$t('overtime_will_be_calculated_after_the_end_of_the_total_schedule_time'))+")\n                ")])]):_vm._e()],2)],1):_vm._e(),_vm._v(" "),(_vm.employeePayrun)?_c('template',{slot:"footer-space"},[_c('button',{staticClass:"btn btn-success mr-3",attrs:{"type":"button"},on:{"click":_vm.restorePayrunPeriod}},[_vm._v("\n            "+_vm._s(this.$t('restore_to_default'))+"\n        ")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }