var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 pb-2"},[_c('div',{staticClass:"d-flex justify-content-between border-bottom py-1 mb-1"},[_c('span',{staticClass:"font-size-90"},[_vm._v(_vm._s(_vm.$t('earning')))]),_vm._v(" "),_c('span',{staticClass:"font-size-90"},[_vm._v("\n                "+_vm._s(!_vm.countTotal('earning').count && !_vm.countTotal('earning').percentage ? `${_vm.formatCurrency(0)}` :
                `${_vm.countTotal('earning').percentage || ''} ${_vm.countTotal('earning').count ?
                    `${(_vm.countTotal('earning').percentage ? '+' : '')} ${_vm.countTotal('earning').count}` : ''}`)+"\n            ")])]),_vm._v(" "),_vm._l((_vm.earnings),function(earning){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"font-size-80"},[_vm._v(_vm._s(earning.beneficiary.name))]),_vm._v(" "),_c('span',{staticClass:"font-size-80"},[_vm._v("\n                    "+_vm._s(parseInt(earning.is_percentage) > 0 ? `${earning.amount} %` :
                    `${_vm.formatCurrency(earning.amount)}`)+"\n                ")])])]})],2),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"d-flex justify-content-between border-bottom py-1 mb-1"},[_c('span',{staticClass:"font-size-90"},[_vm._v(_vm._s(_vm.$t('deduction')))]),_vm._v(" "),_c('span',{staticClass:"font-size-90"},[_vm._v("\n                "+_vm._s(!_vm.countTotal('deduction').count && !_vm.countTotal('deduction').percentage ? `${_vm.formatCurrency(0)}` :
                        `${_vm.countTotal('deduction').percentage || ''} ${_vm.countTotal('deduction').count ?
                            `${(_vm.countTotal('deduction').percentage ? '+' : '')} ${_vm.countTotal('deduction').count}` : ''}`)+"\n            ")])]),_vm._v(" "),_vm._l((_vm.deductions),function(deduction){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"font-size-80"},[_vm._v(_vm._s(deduction.beneficiary.name))]),_vm._v(" "),_c('span',{staticClass:"font-size-80"},[_vm._v("\n                    "+_vm._s(parseInt(deduction.is_percentage) > 0 ? `${deduction.amount} %` :
                    `${ _vm.formatCurrency(deduction.amount) }`)+"\n                ")])])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }