import { render, staticRenderFns } from "./PayrollSummeryCards.vue?vue&type=template&id=97eb9c6c&scoped=true&"
import script from "./PayrollSummeryCards.vue?vue&type=script&lang=js&"
export * from "./PayrollSummeryCards.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97eb9c6c",
  null
  
)

export default component.exports